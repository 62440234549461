import { SafeResourceUrl } from '@angular/platform-browser';

import { DealerBranding } from '@app/core/models/dealer-branding.model';
import { PaymentProvider } from '@app/configure/models/payment-provider.model';
import { CommunicationOpenTimes } from '@app/configure/models/communication-open-times';

export class Dealer {
	id?: number;
	name?: string;
	friendly_name?: string;
	telephone?: string;
	email?: string;
	address1?: string;
	address2?: string;
	address3?: string;
	town?: string;
	county?: string;
	postcode?: string;
	website_url?: string;
	fca_number?: string;
	vat_no?: string;
	company_no?: string;
	branding: DealerBranding;
	google_analytics_code?: string;
	ga4MeasurementId?: string;
	google_tag_manager_id?: string;
	demonstration_mode?: boolean;
	configure_steps?: string[];
	facebook_pixel_id?: string;
	sp_layout?: string;
	visitor_chat_enabled?: boolean;
	website_tracking?: boolean;
	map?: string;
	payment_providers?: PaymentProvider[];
	review_provider?: string;
	review_provider_url?: string;
	review_overall_rating?: number;
	review_count?: number;
	concierge_image?: string;
	test_drive_days?: number;
	test_drive_miles?: number;
	terms_and_conditions_url?: string;
	privacy_policy_url?: string;
	cookie_policy_url?: string;
	enable_distance_selling_terms?: boolean;
	dpo_name?: string;
	dpo_email?: string;
	ico_number?: string;
	custom_footer_text?: string;
	reservation?: { refundable: boolean };
	pex_disclaimer_text?: string;
	finance_module_enabled?: boolean;
	finance_recalculation_enabled?: boolean;
	finance_communications_enabled?: boolean;
	communication_open_times?: CommunicationOpenTimes[];
	show_time_estimates?: boolean;
	finance_api_key?: string;
	codeweavers_finance_form?: boolean;
	use_vehicle_lookup_service?: boolean;

	configure_enabled?: boolean;
	finance_application_enabled?: boolean;

	//  Generated

	domain?: string;
	address?: string;
	google_maps_embed?: SafeResourceUrl;

	constructor(dealer: Dealer) {
		Object.assign(this, dealer);
	}
}
