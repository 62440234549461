import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { catchError, flatMap, map } from 'rxjs/operators';
import { ulid } from 'ulid';

import { environment } from '@environments/environment';

import { ConfigureAPIService } from '@app/configure/services/configure-api.service';
import { DataLayerService } from '@app/core/services/data-layer.service';
import { CoreAPIService } from '@app/core/services/core-api.service';
import { SessionService } from '@app/core/services/session.service';
import { StorageService } from '@app/core/services/storage.service';
import { HelperService } from '@app/core/services/helper.service';
import { ModalService } from '@app/core/services/modal.service';
import { ErrorService } from '@app/core/services/error.service';

import { Dealer } from '@app/core/models/dealer.model';

import { ConfigureStruct } from '@app/configure/structs/configure.struct';
import { FinanceQuote } from '@app/core/models/finance-quote.model';
import { TrackingData } from '@app/core/models/tracking-data.model';

import { DomainHelper } from '@app/core/helpers/domain.helper';

@Injectable()
export class ConfigureService {
	//  SET UP SUBJECTS FOR CONFIGURE DATA AND MAP MODAL

	configure_data: any = ConfigureStruct;
	configure_data$: Subject<any> = new Subject<any>();
	step_mode$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	pex_enquiry_hash$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	dealer: Dealer = this.session_service.getDealer();

	constructor(
		private router: Router,
		private core_api_service: CoreAPIService,
		private configure_api_service: ConfigureAPIService,
		private error_service: ErrorService,
		private modal_service: ModalService,
		private session_service: SessionService,
		private storage_service: StorageService,
		private helper_service: HelperService,
		private data_layer_service: DataLayerService
	) {
		this.session_service.dealer$.subscribe((dealer: Dealer) => {
			this.dealer = dealer;
		});
	}

	// GLOBAL MODALS

	setSoftError(soft_error: any): void {
		this.modal_service.show(['configure/soft-error'], { soft_error });
	}

	//  TARGET

	setStepMode(step_mode: string): void {
		this.step_mode$.next(step_mode || null);
	}

	getStepMode(): string {
		return this.step_mode$.getValue();
	}

	//  PEX

	setPexEnquiryHash(pex_enquiry_hash: string): void {
		this.pex_enquiry_hash$.next(pex_enquiry_hash || null);
	}

	getPexEnquiryHash(): string {
		return this.pex_enquiry_hash$.getValue();
	}

	//  GENERIC

	setData(configure_data?: any, append_additional?: boolean, push_to_data_layer: boolean = true): void {
		if (configure_data) {
			this.configure_data = configure_data;
		}

		if (append_additional) {
			if (typeof configure_data.meta.step_mode !== 'undefined') {
				this.setStepMode(configure_data.meta.step_mode);
			}

			this.setActiveSteps();
			this.checkStepMode();
			this.appendStepsOrder();
			this.appendPurchaseVehicleAdditional();
			this.appendPartExchangeAdditional();
			this.appendFinanceOptionsAdditional();
			this.appendDeliveryOptionsAdditional();
			this.appendPaymentAdditional();

			if (this.dealer.show_time_estimates) {
				this.appendMinutesToComplete();
			}
		}

		if (push_to_data_layer && !this.configure_data.placeholder) {
			this.data_layer_service.pushEnquiry(this.configure_data);
		}

		this.configure_data$.next(this.configure_data);
	}

	setActiveSteps(): void {
		if (this.configure_data.meta.current_step === null) {
			this.configure_data.meta.current_step = 'purchase_vehicle';
		}

		if (this.configure_data.meta.latest_step === null) {
			this.configure_data.meta.latest_step = 'purchase_vehicle';
		}
	}

	checkStepMode(): void {
		const step_mode: string = this.getStepMode();

		if (
			(step_mode === 'part_exchange' && !this.shouldEnableStep('part_exchange')) ||
			(step_mode === 'reserve' && !this.shouldEnableStep('payment'))
		) {
			this.setStepMode('full');
		}
	}

	appendStepsOrder(): void {
		this.configure_data.steps_order = ['purchase_vehicle'];

		if (this.shouldEnableStep('part_exchange')) {
			this.configure_data.steps_order.push('part_exchange');
		}

		if (this.shouldEnableStep('customise_review')) {
			this.configure_data.steps_order.push('customise_review');
		}

		if (this.shouldEnableStep('finance_options')) {
			this.configure_data.steps_order.splice(3, 0, 'finance_options');
		}

		if (this.shouldEnableStep('delivery_options')) {
			this.configure_data.steps_order.push('delivery_options');
		}

		if (this.shouldEnableStep('payment')) {
			this.configure_data.steps_order.push('payment');
		}

		this.configure_data.steps_order.forEach((value: string, index: number) => {
			this.configure_data.steps[value].step_number = index + 1;
		});

		if (this.configure_data.steps_order.length < 2) {
			const error_location: string = 'app/configure/services/configure-service :: appendStepsOrder()';
			this.error_service.show(403, { Message: 'Not enough steps to load' }, error_location);
		}
	}

	isStepDealerEnabled(step_key: string): boolean {
		return this.dealer.configure_steps.indexOf(step_key) > -1;
	}

	isBuyNowEnabled(): boolean {
		if (this.getStepMode() !== 'full') {
			return false;
		}

		if (!this.configure_data.steps.purchase_vehicle.data.enable_full_balance_payment) {
			return false;
		}

		if (this.configure_data.steps.part_exchange.data.length && !this.configure_data.meta.enable_full_balance_payment_with_pex) {
			return false;
		}

		return true;
	}

	hasFullPayment(): boolean {
		return (
			this.configure_data &&
			this.configure_data.steps &&
			this.configure_data.steps.payment &&
			this.configure_data.steps.payment.data &&
			this.configure_data.steps.payment.data.full_payment &&
			this.configure_data.steps.payment.data.full_payment.full_payment_made
		);
	}

	shouldEnableStep(step_key: string): boolean {
		const step_mode: string = this.getStepMode();
		const dealer_enabled: boolean = this.isStepDealerEnabled(step_key);

		if (step_key === 'part_exchange') {
			if (step_mode === 'part_exchange' && dealer_enabled) {
				return true;
			}

			if (step_mode === 'reserve' && dealer_enabled) {
				return !!this.configure_data.steps.part_exchange.data.length;
			}

			return dealer_enabled || this.configure_data.steps.part_exchange.enabled;
		}

		if (step_key === 'customise_review') {
			if (step_mode === 'part_exchange' || step_mode === 'reserve') {
				return false;
			}

			return dealer_enabled || this.configure_data.steps.customise_review.enabled;
		}

		if (step_key === 'finance_options') {
			return dealer_enabled && this.configure_data.meta.finance.available && step_mode !== 'part_exchange' && step_mode !== 'reserve';
		}

		if (step_key === 'delivery_options') {
			return dealer_enabled && step_mode !== 'part_exchange' && step_mode !== 'reserve';
		}

		if (step_key === 'payment') {
			if (step_mode === 'reserve' && dealer_enabled) {
				return true;
			}

			if (step_mode === 'part_exchange' && dealer_enabled) {
				return !!(
					this.configure_data.steps.payment.data.reservation && this.configure_data.steps.payment.data.reservation.reserved
				);
			}

			return dealer_enabled || this.configure_data.steps.payment.enabled;
		}
	}

	isStepEnabled(step_key: string): boolean {
		if (!this.configure_data.steps_order) {
			return false;
		}

		return this.configure_data.steps_order.indexOf(step_key) > -1;
	}

	enableStep(step_key: string): void {
		if (step_key === 'payment' && this.configure_data.steps_order.indexOf('payment') === -1) {
			this.configure_data.steps_order.push(step_key);
			this.configure_data.steps_order.forEach((value: string, index: number) => {
				this.configure_data.steps[value].step_number = index + 1;
			});

			this.configure_data$.next(this.configure_data);
		}
	}

	appendPurchaseVehicleAdditional(): void {
		const purchase_vehicle: any = this.configure_data.steps.purchase_vehicle.data;

		purchase_vehicle.brand_logo_url = this.helper_service.buildManufacturerBrandLogoUrl(purchase_vehicle.make);

		purchase_vehicle.images = purchase_vehicle.images.map((image: string) => {
			return environment.vehicle_image_url + image;
		});

		this.configure_data.steps.purchase_vehicle.data = purchase_vehicle;
	}

	appendPartExchangeAdditional(): void {
		let part_exchanges: any[] = this.configure_data.steps.part_exchange.data;

		part_exchanges = part_exchanges.map((pex: any) => {
			pex.state = 'a';

			if (pex.vehicle !== null) {
				pex.state = 'c';
			} else if (pex.registration !== null && pex.cap_codes !== null && pex.cap_codes.length > 0) {
				pex.state = 'b';
			}

			return pex;
		});

		this.configure_data.steps.part_exchange.data = part_exchanges;
	}

	appendFinanceOptionsAdditional(): void {
		if (this.configure_data.meta.enquiry_hash) {
			let finance_quotes: any[] = this.configure_data.steps.finance_options.data;

			finance_quotes = finance_quotes.map((quote: any) => {
				if (quote.apply_url && quote.apply_url.indexOf('cd_ref=') === -1) {
					quote.apply_url +=
						(quote.apply_url.indexOf('?') === -1 ? '?' : '&') + 'cd_ref=' + this.configure_data.meta.enquiry_hash;
				}

				if (quote.apply_url && quote.apply_url.indexOf('returnurl=') === -1) {
					quote.apply_url +=
						(quote.apply_url.indexOf('?') === -1 ? '?' : '&') +
						'returnurl=' +
						encodeURIComponent(
							[
								window.location.protocol,
								'//',
								window.location.host,
								DomainHelper.getBaseHref(),
								'configure/e/',
								this.configure_data.meta.enquiry_hash,
								this.isStepEnabled('delivery_options') ? '/delivery-options' : '/payment',
							].join('')
						);
				}

				return quote;
			});

			this.configure_data.steps.finance_options.data = finance_quotes;
		}
	}

	appendDeliveryOptionsAdditional(): void {
		if (this.configure_data.meta.enquiry_hash) {
			let delivery_options: any = this.configure_data.steps.delivery_options.data;

			delivery_options.collection.locations = delivery_options.collection.locations.map((location) => {
				const lines = [
					location.address.line_1,
					location.address.line_2,
					location.address.line_3,
					location.address.town,
					location.address.county,
					location.address.postcode,
				];
				location.address_string = this.helper_service.buildAddress(lines, false);
				return location;
			});

			this.configure_data.steps.delivery_options.data = delivery_options;
		}
	}

	getCollectionAddress(): string {
		const collection_location = this.configure_data.steps.delivery_options.data.collection.locations.find((location) => {
			return location.is_selected;
		});

		return collection_location ? collection_location.address_string : null;
	}

	getDeliveryAddressAsArray(): string[] {
		const address = this.configure_data.steps.delivery_options.data.delivery.address;
		const address_lines: string[] = [];

		if (address.line_1) {
			address_lines.push(address.line_1);
		}

		if (address.line_2) {
			address_lines.push(address.line_2);
		}

		if (address.line_3) {
			address_lines.push(address.line_3);
		}

		if (address.line_4) {
			address_lines.push(address.line_4);
		}

		if (address.postcode) {
			address_lines.push(address.postcode);
		}

		return address_lines;
	}

	appendPaymentAdditional(): void {
		let payment_providers = this.configure_data.steps.payment.data.payment_providers;

		if (payment_providers) {
			payment_providers = payment_providers.map((provider) => {
				provider.logo = `${environment.deploy_protocol}${environment.deploy_domain}/assets/img/${provider.reference}.png`;

				return provider;
			});

			this.configure_data.steps.payment.data.payment_providers = payment_providers;
		}
	}

	appendMinutesToComplete(): void {
		this.configure_data.steps.purchase_vehicle.minutes_to_complete = 1;
		this.configure_data.steps.part_exchange.minutes_to_complete = 3;
		this.configure_data.steps.customise_review.minutes_to_complete = 5;
		this.configure_data.steps.finance_options.minutes_to_complete = 10;
		this.configure_data.steps.finance_options.application_minutes_to_complete = 5;
		this.configure_data.steps.delivery_options.minutes_to_complete = 2;
		this.configure_data.steps.payment.minutes_to_complete = 2;
	}

	track(type: string, data: any, enquiry_hash: string = this.configure_data.meta.enquiry_hash): Observable<any> {
		if (enquiry_hash && this.storage_service.getItem('dnt') !== 'true') {
			const body: any = {
				type: type,
				body: data,
			};

			return this.core_api_service
				.trackActivity(enquiry_hash, body)
				.pipe(
					map((response: HttpResponse<any>) => {
						switch (response.status) {
							case 200:
								this.configure_data.meta.current_step = response.body.current_step;
								this.configure_data.meta.latest_step = response.body.latest_step;
								break;

							default:
								break;
						}
					})
				)
				.pipe(
					catchError(() => {
						return of(true);
					})
				);
		}
		return of(true);
	}

	navigateToStep(key: string): void {
		const route: string[] = ['/configure', 'e', this.configure_data.meta.enquiry_hash, this.configure_data.steps[key].route];
		this.router.navigate(route);
	}

	/**
	 * Tracks journey on page change.
	 */
	trackJourney(): Observable<any> {
		let ga_client_id: string = this.storage_service.getCookie('ga_client_id');

		if (!ga_client_id) {
			const ga_ulid = ulid();
			ga_client_id = ga_ulid;
			this.storage_service.setCookie('ga_client_id', ga_ulid, 7);
		}

		if (!this.dealer.website_tracking || this.storage_service.getItem('dnt') === 'true' || !ga_client_id) {
			return of(EMPTY);
		}

		const journey: string = this.storage_service.getCookie('journey') || null;
		const params: Params = JSON.parse(this.storage_service.getItem('params')) || {};

		const trackingData: TrackingData = new TrackingData({
			journey,
			utm_campaign: params['utm_campaign'] || null,
			utm_source: params['utm_source'] || null,
			utm_medium: params['utm_medium'] || null,
			ga_client_id: ga_client_id,
			uri: window.location.pathname.slice(1),
			domain: window.location.hostname,
		});

		return this.configure_api_service.trackJourney(this.configure_data.meta.enquiry_hash || null, trackingData).pipe(
			map((response: HttpResponse<any>) => {
				if (response.body && response.body.journey_token) {
					this.storage_service.setCookie('journey', response.body.journey_token);
				}
				return response;
			})
		);
	}

	//  PART EXCHANGE

	createPartExVehicle(body: any, set_data: boolean = true): Observable<any> {
		return this.configure_api_service
			.createPartExVehicle(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					switch (response.status) {
						case 200:
							const cap_codes: any = response.body;

							if (cap_codes !== null && cap_codes.length > 0) {
								const pex_vehicle: any = {
									registration: body.registration,
									cap_codes: cap_codes,
									vehicle: null,
								};

								this.configure_data.steps.part_exchange.data = [pex_vehicle];
								if (set_data) {
									this.setData();
								}

								return pex_vehicle;
							} else {
								return null;
							}
						case 403:
							return null;
						default:
							return null;
					}
				})
			)
			.pipe(
				catchError(() => {
					return of(null);
				})
			);
	}

	updatePartExVehicle(body: any, set_data: boolean = true): Observable<any> {
		return this.configure_api_service
			.updatePartExVehicle(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					switch (response.status) {
						case 200:
							this.configure_data.steps.part_exchange.data = [response.body];

							if (set_data) {
								this.setData();
							}

							this.data_layer_service.push({
								event: 'partExchangeData',
								partExchangeData: response.body,
							});

							return response.body;

						case 403:
						case 410:
							return null;
						default:
							return null;
					}
				})
			)
			.pipe(
				catchError(() => {
					return of(null);
				})
			);
	}

	deletePartExVehicle(set_data: boolean = true): Observable<boolean> {
		return this.configure_api_service
			.deletePartExVehicle(this.configure_data.meta.enquiry_hash)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						this.configure_data.steps.part_exchange.data = [];
						if (set_data) {
							this.setData();
						}

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure.service :: deletePartExchange()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	//  CONFIGURE & REVIEW

	saveCustomiseReview(set_data: boolean = true, refresh_quotes: boolean = true): Observable<boolean> {
		const body: any = {
			addons: this.configure_data.steps.customise_review.data.addons,
			finance: {
				enabled: this.configure_data.meta.finance.enabled,
				term: this.configure_data.meta.finance.term,
				mileage: this.configure_data.meta.finance.mileage,
				deposit: this.configure_data.meta.finance.deposit,
			},
			refresh_quotes: refresh_quotes,
		};

		if (this.configure_data.meta.finance.show_credit_score) {
			body.finance.credit_score = this.configure_data.meta.finance.credit_score;
		}

		return this.configure_api_service
			.saveCustomiseReview(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						this.configure_data.steps.customise_review.data = response.body.customise_review;
						this.configure_data.steps.finance_options.data = response.body.finance_options;
						this.configure_data.meta.cashback_deal = this.configure_data.steps.customise_review.data.grand_total <= 0;
						this.configure_data.meta.finance.failed = response.body.finance_failed;
						this.appendStepsOrder();
						this.appendFinanceOptionsAdditional();

						if (set_data) {
							this.setData();
						}

						this.data_layer_service.push({
							event: 'customiseReviewData',
							customiseReviewData: response.body,
						});

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: saveCustomiseReview()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	//  FINANCE

	deleteFinanceQuotes(set_data: boolean = true): Observable<boolean> {
		return this.configure_api_service
			.deleteFinanceQuotes(this.configure_data.meta.enquiry_hash)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						if (set_data) {
							this.setData();
						}
						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: deleteFinanceQuotes()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	//  CALLBACK

	saveCallback(body: any, set_data: boolean = true): Observable<any> {
		return this.configure_api_service
			.saveCallback(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						this.configure_data.meta.customer = response.body.customer;
						this.configure_data.meta.callback = response.body.callback;

						if (set_data) {
							this.setData();
						}

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: saveCallback()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	//  CUSTOMER

	createEnquiry(vehicle_id: number, body: any): Observable<any> {
		return this.configure_api_service
			.createEnquiry(vehicle_id, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string =
						'app/configure/components/shared/customer-details/customer-details.component :: saveCustomerDetails() :: switch(this.mode)case:start';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	saveCustomer(body: any, set_data: boolean = true): Observable<any> {
		return this.configure_api_service
			.saveCustomer(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						this.configure_data.meta.customer = response.body.customer;

						if (set_data) {
							this.setData();
						}

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: saveCustomer()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	// DELIVERY OPTIONS

	saveCollection(collection_body: { collection_date: string; collection_location_id?: number | string }): Observable<any> {
		const body = {
			data: {
				collection: {
					date: collection_body.collection_date,
					location_id: collection_body.collection_location_id,
				},
			},
		};

		return this.configure_api_service
			.saveDeliveryOptions(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						this.configure_data.steps.delivery_options.data = response.body.data;
						this.appendDeliveryOptionsAdditional();
						this.configure_data$.next(this.configure_data);

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: saveCollection()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	saveDelivery(delivery_date: string, address): Observable<any> {
		const body = {
			data: {
				delivery: {
					date: delivery_date,
					address,
				},
			},
		};

		return this.configure_api_service
			.saveDeliveryOptions(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						this.configure_data.steps.delivery_options.data = response.body.data;
						this.setData();

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: saveDelivery()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	//  RESERVE

	generatePaymentToken(service: string): Observable<any> {
		return this.configure_api_service
			.generatePaymentToken(
				service,
				this.configure_data.steps.payment.data.reservation.reservation_amount,
				this.configure_data.meta.enquiry_hash
			)
			.pipe(
				map((response: HttpResponse<any>) => {
					return response.body.id;
				})
			)
			.pipe(
				catchError(() => {
					return of(null);
				})
			);
	}

	createPaymentCharge(body: any, payment_type: 'buyNow' | 'reserve', set_data: boolean = true): Observable<any> {
		let financeDisabled = of(false);
		let customiseReview = of(false);

		if (payment_type === 'buyNow') {
			financeDisabled = this.setFinanceEnabled(false, false);
			customiseReview = this.saveCustomiseReview(false);
		}

		const createPaymentCharge = this.configure_api_service
			.createPaymentCharge(this.configure_data.meta.enquiry_hash, body, payment_type)
			.pipe(
				map((response: HttpResponse<any>) => {
					return this.handlePaymentResponse(response, payment_type, set_data);
				})
			)
			.pipe(
				catchError(() => {
					return of(false);
				})
			);

		return financeDisabled.pipe(flatMap(() => customiseReview.pipe(flatMap(() => createPaymentCharge))));
	}

	validatePaymentCharge(body: any, payment_type: 'buyNow' | 'reserve', set_data: boolean = true): Observable<any> {
		return this.configure_api_service
			.validatePayment(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					return this.handlePaymentResponse(response, payment_type, set_data);
				})
			)
			.pipe(
				catchError(() => {
					return of(false);
				})
			);
	}

	handlePaymentResponse(response: any, payment_type: 'buyNow' | 'reserve', set_data: boolean = true) {
		if (response.ok) {
			if (response.body.requires_action) {
				return {
					status: 'requires_action',
					token: response.body.client_token,
					id: response.body.payment_id,
					method: response.body.payment_method,
				};
			}

			if (payment_type === 'buyNow') {
				this.configure_data.steps.payment.data.full_payment = response.body;
			} else {
				this.configure_data.steps.payment.data.reservation = response.body;
			}

			if (set_data) {
				this.setData();
			}

			return true;
		}
	}

	// META

	setFinanceLocked(locked: boolean, set_data: boolean = true): Observable<any> {
		this.configure_data.meta.finance.locked = locked;

		if (set_data) {
			this.setData();
		}

		return of(true);
	}

	setPreferredFinanceFacilityType(facility_type: string, set_data: boolean = true): Observable<any> {
		this.configure_data.meta.preferred_facility_type = facility_type;
		const body: any = {
			preferred_facility_type: facility_type,
		};

		return this.setMeta(body, false)
			.pipe(
				flatMap(() => {
					return this.saveCustomiseReview(set_data, false);
				})
			)
			.pipe(
				catchError(() => {
					return of(false);
				})
			);
	}

	setFinanceEnabled(enabled: boolean, set_data: boolean = true): Observable<any> {
		this.configure_data.meta.finance.enabled = enabled;
		const body: any = {
			finance_enabled: enabled,
		};

		return this.setMeta(body, set_data).pipe(
			catchError(() => {
				return of(false);
			})
		);
	}

	setMeta(body: any, set_data: boolean = true): Observable<any> {
		return this.configure_api_service
			.setMeta(this.configure_data.meta.enquiry_hash, body)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						if (set_data) {
							this.setData();
						}

						return true;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: setMeta()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	createCodeweaversProposalFromQuote(quote_uuid: string) {
		return this.configure_api_service
			.createCodeweaversProposalFromQuote(this.configure_data.meta.enquiry_hash, quote_uuid)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError((response: HttpErrorResponse) => {
					const error_location: string = 'app/configure/services/configure-service :: createCodeweaversProposalFromQuote()';
					this.error_service.show(response.status, response, error_location);
					return of(false);
				})
			);
	}

	//  VEHICLE LOOKUP

	getVehicleTypes(): Observable<any> {
		return this.core_api_service
			.getVehicleTypes()
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError(() => {
					return of(false);
				})
			);
	}

	getVehicleManufacturers(type: string | null = null): Observable<any> {
		return this.core_api_service
			.getVehicleManufacturers(type)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError(() => {
					return of(false);
				})
			);
	}

	getVehicleRanges(manufacturer_id: number, type: string | null = null): Observable<any> {
		return this.core_api_service
			.getVehicleRanges(manufacturer_id, type)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError(() => {
					this.showLookupError();
					return of(false);
				})
			);
	}

	getVehicleModels(manufacturer_id: number, range_id: number): Observable<any> {
		return this.core_api_service
			.getVehicleModels(manufacturer_id, range_id)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.status) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError(() => {
					this.showLookupError();
					return of(false);
				})
			);
	}

	getVehicleEditions(manufacturer_id: number, range_id: number, model_id: number): Observable<any> {
		return this.core_api_service
			.getVehicleEditions(manufacturer_id, range_id, model_id)
			.pipe(
				map((response: HttpResponse<any>) => {
					if (response.ok) {
						return response.body;
					}
				})
			)
			.pipe(
				catchError(() => {
					this.showLookupError();
					return of(false);
				})
			);
	}

	showLookupError(): void {
		this.setSoftError({
			title: 'Vehicle lookup failed',
			message:
				"We're sorry, online valuations are not available at this time. Please arrange a callback so that we are able to discuss your part exchange details.",
		});
	}

	getAddonOptions(): any {
		const options: any[] = [];

		this.configure_data.steps.customise_review.data.addons.forEach((addon: any) => {
			addon.options.forEach((option: any) => {
				options.push(option);
			});
		});

		return options;
	}

	/**
	 * Get all of the selected addons
	 */
	getSelectedAddons(): any {
		return this.getAddonOptions().filter((option: any) => {
			return option.selected;
		});
	}

	/**
	 * Get the vatable addons
	 */
	getVatableAddons(): any {
		return this.getSelectedAddons().filter((option: any) => {
			return option.vat;
		});
	}

	/**
	 * Get the total price of the part exchange
	 *
	 * @returns {number}
	 */
	getTotalPexPrice(): number {
		const partExchanges: any[] = this.configure_data.steps.part_exchange.data;
		let total: number = 0;

		if (partExchanges.length) {
			partExchanges.forEach((pex: any) => {
				if (pex.state === 'c') {
					let pexTotal: number = pex.vehicle.allowance;

					if (pex.vehicle.finance_settlement) {
						pexTotal = pex.vehicle.allowance - pex.vehicle.finance_settlement;
					}

					total += pexTotal;
				}
			});
		}

		return total;
	}

	/**
	 * Get the selected quote
	 */
	getSelectedQuote(): any {
		return this.configure_data.steps.finance_options.data.find((quote: FinanceQuote) => {
			return quote.selected;
		});
	}

	getTotalAddonOptionsContribution(): number {
		return this.getSelectedAddons().reduce((option: any) => {
			if (!option.financeable) {
				return option.amount;
			}

			if (option.vat) {
				return option.amount - option.net_amount;
			}
		});
	}

	/**
	 * Get the total cash deposit.
	 *
	 * @param quote
	 * @returns {number}
	 */
	getTotalCashDeposit(quote: FinanceQuote = null): number {
		let financeDeposit: number = this.getSelectedQuote().total_deposit;

		if (quote) {
			financeDeposit = quote.total_deposit;
		}

		financeDeposit -= this.getTotalPexPrice();

		this.getSelectedAddons().forEach((option: any) => {
			const vehicleHasVat: boolean = this.configure_data.steps.purchase_vehicle.data.add_vat;

			if (!option.financeable) {
				financeDeposit -= vehicleHasVat ? option.net_amount : option.amount;
			}
		});

		if (this.configure_data.steps.purchase_vehicle.data.add_vat) {
			financeDeposit -= this.configure_data.steps.customise_review.data.grand_total_vat;
		}

		return financeDeposit;
	}

	/**
	 * Get the total deposit including pex, addons and cash.
	 *
	 * @returns {number}
	 */
	getTotalDeposit(quote: FinanceQuote = null): number {
		let total: number = 0;
		let deposit: number = this.configure_data.meta.finance.deposit;

		if (quote) {
			deposit = quote.total_deposit;
		}

		total += this.getTotalAddonOptionsContribution();
		total += this.getTotalPexPrice();
		total += deposit;

		if (this.configure_data.steps.purchase_vehicle.data.add_vat) {
			total += this.configure_data.steps.customise_review.data.grand_total_vat;
		}

		return total;
	}

	markCalculationAsSubmitted(quote: FinanceQuote): Observable<any> {
		return this.configure_api_service.markCalculationAsSubmitted(this.configure_data.meta.enquiry_hash, quote.enq_finance_quotes_id);
	}
}
